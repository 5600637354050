@import bootstrap/dist/css/bootstrap.css
@import ~bootstrap-datepicker/dist/css/bootstrap-datepicker
@import ../stylesheets/bootstrap-multiselect.css
@import ../stylesheets/titles.css.scss

$fa-font-path: '~@fortawesome/fontawesome-free-webfonts/webfonts'

@import ~@fortawesome/fontawesome-free-webfonts
@import @fortawesome/fontawesome-free/scss/fontawesome
@import @fortawesome/fontawesome-free/scss/solid
@import @fortawesome/fontawesome-free/scss/regular
@import @fortawesome/fontawesome-free/scss/brands
@import @fortawesome/fontawesome-free/scss/v4-shims



.text-muted2
  color: #BBBBBB

.form-control-static
  min-height: unset

a
  color: #337AB7

  &.btn
    color: white

  &:visited
    color: #337AB7

  &.btn:visited
    color: white

