// Place all the styles related to the Titles controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

.form-group {
  margin-bottom: 7px;
  line-height: 1.4;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.open>a:focus{
  text-decoration: none;
  color: #f5f5f5;
  background-color: #777777;
}

.form-horizontal .control-label,
.form-control-static{
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus{
  color: #f5f5f5;
  background-color: #777777;
}

.badge {
  background-color: #428bca;
}

.dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus {
  background-color: #777777;
}

a.money:-webkit-any-link{
  text-decoration: underline;
  cursor: auto;
}

/*a.money {
  color: #288026;
  text-decoration: none;
}*/

a.non_money:-webkit-any-link{
  text-decoration: underline;
  cursor: auto;
}

/*a.non_money {
  color: #fa0726;
  text-decoration: none;
}*/

.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus{
  color: #f5f5f5;
  background-color: #777777;
}

.dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus{
  text-decoration: none;
  color: #f5f5f5;
  background-color: #777777;
}